import * as React from "react"
import { Link } from "gatsby"

const Header = () => {
  return (
    <div className="fixed bg-black w-full p-4 z-50">

      <header className="w-full max-w-screen-xl mx-auto">
        <nav className="flex items-center justify-between mx-auto">
          <Link to="/">
            <div className="md:w-48 flex items-center">
              <div>
                <h2 className="text-white text-xl uppercase">
                  Micro-Captive
                </h2>
              </div>
            </div>
          </Link>

          <div className="flex-shrink-0 md:w-48">
            <Link to="/">
              <button className="w-full bg-red-500 hover:bg-red-600 text-white font-display px-6 py-2 font-semibold uppercase tracking-wider">
                Contact
              </button>
            </Link>
          </div>
        </nav>
      </header>
    </div>
  )
}

export default Header